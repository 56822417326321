<template>
  <div class="div">
    <Navbar />
    <main id="main" class="main">
      <section
        class="home-banner-section inner-banner cover-bg"
        style="background-image:url(../../assets/images/products-banner.png);"
      >
        <div class="banner-caption">
          <div class="banner-caption-content text-white">
            <div
              class="banner-caption-text d-flex w-100 align-items-center flex-warp h-100 py-5"
            >
              <div class="container">
                <div class="banner-text">
                  <h1 class="title-border">Our <span>Products</span></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="curved-section-main">
        <!-- ! Section start -->
        <section
          class="section curved-section no-min-height"
          style="background-image: url(../../assets/images/products-bg.png);"
        >
          <div class="section-shape section-shape-top">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1920"
              height="84.752"
              viewBox="0 0 1920 84.752"
              class="shape-1"
            >
              <g transform="translate(0 -0.248)">
                <g transform="translate(0 -402)">
                  <path
                    d="M0,439.134q262.562,38.639,458.711,38.638c196.15,0,411.934-35.14,747.269-67.579q335.34-32.44,714.02,67.579V487H0Z"
                    fill-rule="evenodd"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div class="container section-container-padding ">
            <div class="section-top-bar d-flex">
              <div class="section-top-bar-container">
                <h2 class="section-title">
                  VAXTRAX GLOBAL <span>Products</span>
                </h2>
              </div>
            </div>
            <div class="odd-even-col">
              <div class="row g-0 odd-even-block">
                <div class="col-lg-6 text-center">
                  <img src="../../assets/images/degital-card.png" />
                </div>
                <div class="odd-even-right-col col-lg-6">
                  <div
                    class="odd-even-content-main d-flex align-items-center h-100 flex-wrap"
                  >
                    <div class="odd-even-content">
                      <h3 class="mb-3">VAXTRAX Card</h3>
                      <p>
                        This is a plastic card with all your credentials and
                        unique QR CODE that can be presented to the vendor
                        instead of your mobile device.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row g-0 odd-even-block">
                <div class="col-lg-6 text-center">
                  <img src="../../assets/images/visa-stickers.png" />
                </div>
                <div class="odd-even-right-col col-lg-6">
                  <div
                    class="odd-even-content-main d-flex align-items-center h-100 flex-wrap"
                  >
                    <div class="odd-even-content">
                      <h3 class="mb-3">VAXTRAX Passport Sticker</h3>
                      <p>
                        This is a sticker with all your credentials and unique
                        QR CODE that can be add to your passport blank page for
                        presented to the vendor instead of your mobile device
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row g-0 odd-even-block">
                <div class="col-lg-6 text-center">
                  <img src="../../assets/images/certification.png" />
                </div>
                <div class="odd-even-right-col col-lg-6">
                  <div
                    class="odd-even-content-main d-flex align-items-center h-100 flex-wrap"
                  >
                    <div class="odd-even-content">
                      <h3 class="mb-3">VAXTRAX certification</h3>
                      <p>
                        This certification process is for validating the
                        uploaded file (vaccine or test) from the administering
                        medical institutions. By uploading your document, check
                        the Certify box and paid the fee, you are authorizing
                        VAXTRAX GLOBAL to share your information with the
                        administering medical institutions.
                      </p>
                      <p>
                        It is a Federal offense to upload fake or modified
                        documents. fines will be up to $10,000
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- ! Section end -->
        <section
          class="section curved-section no-min-height"
          style="background-image: url(../../assets/images/products-bg.png);"
        >
          <div class="section-shape section-shape-top">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1920"
              height="84.752"
              viewBox="0 0 1920 84.752"
              class="shape-1"
            >
              <g transform="translate(0 -0.248)">
                <g transform="translate(0 -402)">
                  <path
                    d="M0,439.134q262.562,38.639,458.711,38.638c196.15,0,411.934-35.14,747.269-67.579q335.34-32.44,714.02,67.579V487H0Z"
                    fill-rule="evenodd"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div class="container section-container-padding ">
            <div class="section-top-bar d-flex">
              <div class="section-top-bar-container">
                <h2 class="section-title">
                  Antigen Rapid <span>Test Kit</span>
                </h2>
                <p>
                  VTG has partnered with a manufacturer of Rapid Test Kit for
                  supplying to its users. These tests can be uploaded into the
                  platform for instant results. The SARS-CoV-2 test kits have
                  FDA-EUA and CE certifications with results in 15 minutes if
                  the qualitative detection of specific antigens of SARS-CoV-2
                  present in the human nasopharynx.
                </p>
              </div>
            </div>
            <div class="row mb-n4 justify-content-center">
              <div class="col-lg-4 col-md-6 mb-4">
                <div class="test-kit-card h-100 text-center d-flex flex-column">
                  <div class="test-kit-card-image mb-4">
                    <img
                      src="../../assets/images/celltrion.png"
                      alt="Celltrion"
                      width="420"
                      height="418"
                    />
                  </div>
                  <div class="test-kit-card-body">
                    <div class="card-details mb-4">
                      <h4>Celltrion</h4>
                      <p>
                        Celltrion DiaTrustTM COVID-19 Ag Rapid Test is a rapid
                        test based on lateral flow immunoassay intended for the
                        qualitative detection of nucleocapsid proteins and
                        receptor binding domains (RBDs) from the SARS-CoV-2
                        spike proteins in human nasopharyngeal swab specimens
                        directly collected from individuals
                      </p>
                    </div>
                  </div>
                  <div class="card-action mt-auto">
                    <p>
                      <a
                        href="../../assets/pdf/celltrion-instructions.pdf"
                        title="Download PDF"
                        class="mb-2 pfd-download-btn"
                        download
                        ><i class="bi bi-file-pdf me-2"></i>Download PDF</a
                      >
                    </p>
                    <p>
                      <a
                        href="https://www.youtube.com/watch?v=__1wUcb6fss"
                        title="Watch Video"
                        class="btn btn-orange venobox"
                        data-vbtype="video"
                        data-autoplay="true"
                        >Watch Video <i class="ms-2 fas fa-arrow-right"></i
                      ></a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-4">
                <div
                  class="test-kit-card h-100 text-center  d-flex flex-column"
                >
                  <div class="test-kit-card-image  mb-4">
                    <img
                      src="../../assets/images/flowflex.png"
                      alt="FlowFlex"
                      width="420"
                      height="418"
                    />
                  </div>
                  <div class="test-kit-card-body">
                    <div class="card-details mb-4">
                      <h4>FlowFlex</h4>
                      <p>
                        A rapid test for the detection of SARS-CoV-2 antigens in
                        anterior nasal specimens directly from individuals
                        within 7 days of symptom onset or without symptoms or
                        other epidemiological reasons to suspect COVID-19
                        infection.
                      </p>
                    </div>
                  </div>
                  <div class="card-action mt-auto">
                    <p>
                      <a
                        href="../../assets/pdf/flow-flex.pdf"
                        title="Download PDF"
                        class="mb-2 pfd-download-btn"
                        download
                        ><i class="bi bi-file-pdf me-2"></i>Download PDF</a
                      >
                    </p>
                    <p>
                      <a
                        href="https://www.youtube.com/watch?v=9ShFIzJtaT8"
                        title="Watch Video"
                        class="btn btn-orange venobox"
                        data-vbtype="video"
                        data-autoplay="true"
                        >Watch Video <i class="ms-2 fas fa-arrow-right"></i
                      ></a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-4">
                <div class="test-kit-card h-100 text-center d-flex flex-column">
                  <div class="test-kit-card-image  mb-4">
                    <img
                      src="../../assets/images/instant-saure.png"
                      alt="InstantSure"
                      width="420"
                      height="418"
                    />
                  </div>
                  <div class="test-kit-card-body">
                    <div class="card-details mb-4">
                      <h4>InstantSure</h4>
                      <p>
                        This kit is used for in vitro qualitative detection of
                        SARS-CoV-2 antigen in human nasal swabs from individuals
                        suspected of COVID-19 within the first seven days of
                        symptom onset. InstantSure Covid-19 Ag CARD shall not be
                        used as sole basis to diagnose or exclude SARS-CoV-2
                        infection
                      </p>
                    </div>
                  </div>
                  <div class="card-action mt-auto">
                    <p>
                      <a
                        href="../../assets/pdf/instant-sure.pdf"
                        title="Download PDF"
                        class="mb-2 pfd-download-btn"
                        download
                        ><i class="bi bi-file-pdf me-2"></i>Download PDF</a
                      >
                    </p>
                    <p>
                      <a
                        href="https://www.youtube.com/watch?v=ikEK2EC_okc"
                        title="Watch Video"
                        class="btn btn-orange venobox"
                        data-vbtype="video"
                        data-autoplay="true"
                        >Watch Video <i class="ms-2 fas fa-arrow-right"></i
                      ></a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- ! Section start -->
      <section
        id="lets_talk"
        class="section curved-section lets-talk-section cover-bg text-white"
        style="background-image:url(../../assets/images/contact-blockimage.jpg);"
      >
        <!--   <section class="section curved-section lets-talk-section cover-bg"> -->
        <div class="container section-container-padding">
          <div class="section-top-bar d-flex">
            <div class="section-top-bar-container">
              <h2 class="section-title title-white">Let's <span>talk</span></h2>
            </div>
          </div>
          <div class="contact-box">
            <form
              action="forms/contact.php"
              method="post"
              role="form"
              class="php-email-form h-100"
            >
              <div class="row">
                <div class="col-md-6 form-group mb-4">
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    id="name"
                    placeholder="Your Name"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div class="validate"></div>
                </div>
                <div class="col-md-6 form-group mb-4">
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    data-rule="email"
                    data-msg="Please enter a valid email"
                  />
                  <div class="validate"></div>
                </div>
              </div>
              <div class="form-group mb-4">
                <input
                  type="text"
                  class="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 8 chars of subject"
                />
                <div class="validate"></div>
              </div>
              <div class="form-group mb-4">
                <textarea
                  class="form-control"
                  name="message"
                  rows="5"
                  data-rule="required"
                  data-msg="Please write something for us"
                  placeholder="Message"
                ></textarea>
                <div class="validate"></div>
              </div>
              <div class="mb-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-orange" type="submit">
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <!-- ! Section end -->
    </main>
    <!-- ! Main end -->
    <!-- <div class="push"></div> -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
// import Navbar from "../NavbarTry.vue";
// import Footer from "....//FooterTry.vue";
import Navbar from "@/components/Navbar.vue";
// import Footer from "../../components/Footer.vue";
export default {
  components: {
    Navbar,
    //Footer,
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/screen.css";


</style>
